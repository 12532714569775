import React, { useEffect, useState } from "react";
import { Button, Img, Line, Text } from "components";
import { useGetById, useGetByIdpack } from "utils/functions";
import { useParams, useNavigate } from "react-router-dom";
import { Cart, drawerState } from "../../utils/recoil/atoms";
import { useRecoilState } from "recoil";
import { Rating } from "components/rating";
import CartToggle from "components/drawer-card";
import { useMediaQuery } from "react-responsive";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { TiTick } from 'react-icons/ti'
import Flex from "components/Flex/flex";
import Width from "components/width/width";
import { Navigation } from "swiper/modules";

import { SwiperSlide, Swiper } from "swiper/react";
import { P18, P20, P21 } from "components/TXT/TXT";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const PackDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cart, setCart] = useRecoilState(Cart);
  const [openDrawer, setopenDrawer] = useState(false);

  const [count, setCount] = useState(1);
  const [product, setProduct] = useState();
  const getByIds = useGetByIdpack();
  function getData(data) {
    setopenDrawer(data);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getByIds(id);
        console.log(response);
        setProduct(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (cart.length > 0 && product) {
      let arr = cart.find(x => x._id == product._id)
      if (arr)
        setCount(arr.count)
    }
  }, [cart, product])

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const [number, setNumber] = useState(0)

  const [drawer, setDrawer] = useRecoilState(drawerState);
  const [img, setImg] = useState("");

  useEffect(() => {

    if (product && product.images.length > 0)
      setImg(product.images[0])

  }, [product])
  
  const addToCart = (type) => {
    const productWithCount = { ...product, count, type };
    setProduct(productWithCount)
    console.log(productWithCount);

    if (!cart) {
      setCart([productWithCount]);
    } else {
      const findFilter = cart.find(x => x._id === productWithCount._id);
      console.log("findFilter", findFilter);

      if (findFilter) {
        const updatedCart = cart.map(x => {
          if (x._id === productWithCount._id) {
            return { ...x, count: x.count + 1 };
          }
          return x;
        });
        setCart(updatedCart);
      } else {
        setCart([...cart, productWithCount]);
      }
    }
    setDrawer(true);
  };

  const navigates = useNavigate();

  function handleSendCheckout(pack,selected){
    console.log(pack);
    
    if(pack.length > 0)
    navigates('/CheckoutPack/'+pack+"/"+selected)
  }

  if (product) {
    return (
      <div className="flex md:flex-col flex-row font-montserrat md:gap-5 items-start justify-start mt-[46px] w-full">
        <CartToggle onchange={getData} opens={openDrawer} />
        <div className="md:h-[300px] h-[530px] ml-3.5 md:ml-[0] relative w-[48%] md:w-full">
          <div className="h-[100%] inset-[0] justify-center m-auto w-full">
            {isTabletOrMobile ?
              <Swiper navigation={true} modules={[Navigation]} className="mySwipe h-[100%]">
                {product.images.map(x => {
                  return (
                    <SwiperSlide className=" h-[100%]">
                      <img src={process.env.REACT_APP_API_BACK_IMG + "/uploads/" + x} alt="ddd" className="rounded-lg w-[100%] h-[100%] object-cover" />
                    </SwiperSlide>
                  )
                })}

              </Swiper>
              :
              <Flex className="w-[100%] h-[100%] md:gap-[8px]" flex="between">
                <Width width={"25%"} className="h-[100%]">
                  <Flex flex="start" className="flex-col h-full" gap="1.5%">

                    {product.images.map(x => {
                      return (
                        <div className="h-[22%] w-[100%] cursor-pointer rounded-md" onClick={e => setImg(x)}>
                          <img src={process.env.REACT_APP_API_BACK_IMG + "/uploads/" + x} alt="" className="w-[100%] h-[100%] rounded-md" />
                        </div>
                      )
                    })}
                  </Flex>
                </Width>
                <Width width={"73%"} className="h-[100%] rounded-md">
                  <div className="h-full w-[100%]">
                    <img src={process.env.REACT_APP_API_BACK_IMG + "/uploads/" + img} alt="" className="w-[100%] h-[100%] object-cover rounded-md" />
                  </div>
                </Width>

              </Flex>
            }



          </div>
        </div>
        <div className="flex md:flex-1 flex-col items-start justify-center ml-10 md:ml-[0] w-[51%] md:w-full ">

          <Text
            className="sm:text-4xl md:text-[38px] text-[40px] text-black-900  md:text-[18px]"
            size="txtCormorantBold40Black900"
          >
            {product.title}
          </Text>

          <div onClick={(e) => navigate(`/presphoto/${product.owner._id}`)} className="flex flex-col font-montserrat items-start justify-start mt-[9px] w-[70%] md:w-full gap-[10px]">
            <div className="flex gap-[5px] items-center w-[100%]">

              <Img
                className="h-[64px]  md:h-[56px] md:ml-[0] mr-[3%] rounded md:w-[56px] w-[64px] object-cover"
                src={
                  product.owner?.picture
                    ? process.env.REACT_APP_API_BACK_IMG +
                    "/uploads/" +
                    product.owner?.picture
                    : "../images/defaultProfilePic.jpg"
                }
                alt="femaleSixteen"
              />
              <div>
                <Text
                  className="sm:text-[17px] pt-[2%] md:text-[19px] text-[20px] text-black-900 tracking-[1.05px] text-red-300 font-black cursor-pointer uppercase"
                  size="txtMontserratRegular21"
                >
                  {product.owner.businessName}
                </Text>
                <div className="flex gap-[8px] mt-1 items-end w-[100%]">
                  <Rating val={product.note}></Rating>
                  <Text
                    className="text-base md:text-[17px] text-black-900 "
                    size="txtMontserratRegular16"
                  >
                    <span className="text-black-900 font-calistoga text-left font-normal">
                      {product.note}/
                    </span>
                    <span className="text-red-300_d3 font-calistoga text-left font-normal">
                      5
                    </span>
                  </Text>
                </div>
              </div>
            </div>


          </div>
        
          <Text
            className="leading-[22.00px] mt-[30px] text-base text-black-900_99 w-full"
            size="txtMontserratRegular16Black90099"
          >
            {product.description}
          </Text>

          <div className=" w-[100%] p-3">
            <Tabs selectedIndex={number} onSelect={(index) => setNumber(index)}>
              <TabList>
                {product.packs.map((x, index) => (
                  <Tab key={index}>
                    <P20 className={"p-3"}>{x.title}</P20>
                  </Tab>
                ))}
              </TabList>

              {product.packs.map((pack, index) => (
                <TabPanel key={index}>
                  <div className="w-[100%]">
                    <div className="flex justify-between items-center mt-5">
                      <P18 weight={700}>{pack.title}</P18>
                      <P21 className={"text-gray_800_01"}>{pack.price} TND</P21>
                    </div>
                    <div className="my-5">
                      <p>{pack.description}</p>
                    </div>
                    <div className="my-3">
                      {pack.services.map((service, i) => (
                        <div className="flex my-3 gap-[8px]" key={i}>
                          <TiTick />
                          <p>{service}</p>
                        </div>
                      ))}
                    </div>
                    <div className="w-[100%]">
                      <div onClick={e => handleSendCheckout(product._id,pack._id)}className="bg-red-300 flex flex-col h-[52px] mt-4 inset-[0] items-center justify-center m-auto p-[15px] rounded-[5px] w-[100%]">
                        <Button className="m-auto text-base w-auto" size="txtMontserratMedium16WhiteA700" color="black">
                          Continuer
                        </Button>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>

          </div>




        </div>

      </div>
    );
  } else {
    return <></>;
  }
};

export default PackDetails;
