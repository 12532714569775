import React from "react";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  RatingBar,
  SelectBox,
  Text,
} from "components";

const NotFound = () => {
  return (
    <div>
      <h1>Opps! We ran out of code</h1>
    </div>
  );
};

export default NotFound;
