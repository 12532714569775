import { Heading } from "components";
import Flex from "components/Flex/flex";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import Width from "components/width/width";
import React, { useEffect, useState } from "react";
import './style.css';
import { useGetUser } from "utils/functions";
import { User } from "utils/recoil/atoms";
import { deletefav } from "service/api";
import toast from "react-hot-toast";
import { useMutation } from "react-query";

const Favorite = () =>{

 const [userData, setUser] = useState();
  const GetUser = useGetUser();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let userData = await GetUser(User);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchUser();
  }, []);

  const mutationSupp = useMutation(deletefav, {
    onSuccess: (data) => {
        toast.success('element supprimé avec succés')
        setTimeout(() => {
            window.location.reload()
        }, 3000);
    },
    onError: (error) => {
        toast.error('Erreur serveur')
      console.error('Error creating item:', error);
    },
    });

  const deletes = (id) =>{
        
    mutationSupp.mutate({id})
}
    return(
        <>
    <NavBar></NavBar>
      <div className="  mt-7 md:mt-5 py-10 md:py-5 w-[100vw] h-[100%] md:h-[100%]  flex items-center justify-center">
        <div
          className="w-[60vw] h-[100%] md:w-[95%] md:mx-auto shadow-box  py-8"
          style={{ border: "1px solide black", backgroundColor: "white",height:"100%",borderRadius:"8px" }}
        >
          <div className="pt-[2%] md:py-6 flex flex-col align-center w-[100%] gap-y-15px">
            <div className="flex items-center  justify-center  mx-4">
                <Heading
                    size="s"
                    as="h1"
                    className="text-red-300 tracking-[2.72px]"
                >
                    Votre liste favoris
                </Heading>
            </div>
            <Width className="my-4">
                <table border={"1"}>
                    <thead>
                        <th style={{width:"20%"}}>
                            Image
                        </th>
                        <th style={{width:"35%"}}>
                            Title
                        </th>
                        <th style={{width:"20%"}}>
                            Prix
                        </th>
                        <th style={{width:"25%"}}>
                            Actions
                            
                        </th>
                    </thead>
                    <tbody>
                        {userData && userData?.user.fav && userData?.user.fav.length > 0 && userData?.user.fav.map(x=>{
                            return(
                                <tr>
                                    <td >
                                        <img height={"120px"} width={"100%"} src={process.env.REACT_APP_API_BACK_IMG+"/uploads/"+x.images[0]} alt="" />
                                    </td>
                                    <td>
                                    {x.title}
                                    </td>
                                    <td>
                                    {x.price}
                                    </td>
                                    <td
                                    
                                    >

                                        <button  onClick={
                                        e => deletes(x._id)
                                    }>
                                        Supprimer
                                    </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Width>
          </div>
        </div>
      </div>
      <Footer></Footer>
    
    </>
    )
}

export default Favorite;