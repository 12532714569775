import { Rating } from "components/rating";
import React, { useEffect, useState } from "react";
import './style.css';
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import Width from "components/width/width";
import Flex from "components/Flex/flex";
import { Button } from "components";
import { Cart } from "utils/recoil/atoms";
import { useRecoilState } from "recoil";
import { useMediaQuery } from "react-responsive";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { ajouterfav, checkIfFav, deletefav } from "service/api";
import toast from "react-hot-toast";

export const CardItems = ({ product, isPlanner = false }) => {

    const navigate = useNavigate()
    const [cart, setCart] = useRecoilState(Cart);
    const queryClient = useQueryClient();

    const { data, error, isLoading } = useQuery(
        ['items-fav-peo', product._id], // Unique key with product ID
        () => checkIfFav(product._id), // Pass a function that returns a promise
        {
          enabled: true, // Keep it true if you want to fetch initially
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: false,
          refetchIntervalInBackground: false,
          refetchInterval: false,
        }
      );
    

    const mutation = useMutation(ajouterfav, {
        onSuccess: (data) => {
            toast.success('element ajouter avec succés')
            queryClient.invalidateQueries(['items-fav-peo']);
        },
        onError: (error) => {
            toast.error('Erreur serveur')
          console.error('Error creating item:', error);
        },
    });
    
    const mutationSupp = useMutation(deletefav, {
        onSuccess: (data) => {
            toast.success('element supprimé avec succés')
            queryClient.invalidateQueries(['items-fav-peo']);
        },
        onError: (error) => {
            toast.error('Erreur serveur')
          console.error('Error creating item:', error);
        },
    });


    const add = (id) =>{
        
        mutation.mutate({id})
    }

    const deletes = (id) =>{
        
        mutationSupp.mutate({id})
    }
    
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    return (

        <div className={isPlanner ? ` card w-[100%] h-[410px] md:h-[380px] relative rounded-lg box-shadow my-5` : `w-[100%] h-[360px] md:h-[300px] relative rounded-lg box-shadow my-5`}  >
            {product &&
                <div className="flex flex-col justify-between h-[100%]">
                    <div className="pos-rate bg-red-300" >
                        <div className="flex items-center gap-[5px]">
                            <Rating val={1} count={1} activeColor="white" size={isTabletOrMobile ? 12 : 16}></Rating>
                            <p className="text-rating-b font-montserrat">{product.note}</p>
                        </div>
                    </div>
                    {
                        product.ispack ?
                            <div className="pos-price bg-red-300">
                                <p className="capitalize cursor-pointer text-title-prices">PACK</p>
                            </div>
                            :
                            <div className="pos-price bg-red-300">
                                <p className="capitalize cursor-pointer text-title-prices">{product.price} TND</p>
                            </div>

                    }

                    <div className="w-[100%] h-[75%] md:h-[70%] rounded-lg">
                        <Swiper navigation={false} modules={[Navigation]} className="mySwiper h-[100%]">
                            {product.images.map(x => {
                                return (
                                    <SwiperSlide className=" h-[100%]">
                                        <img src={process.env.REACT_APP_API_BACK_IMG + "/uploads/" + x} alt="ddd" className="rounded-lg w-[100%] h-[100%] object-cover" />
                                    </SwiperSlide>
                                )
                            })}

                        </Swiper>

                    </div>

                    <div className="h-[25%] md:h-[30%] flex-col justify-center flex px-3 gap-[13px] " >
                        <Flex flex="between" gap="2%">
                            <Width width={"15%"}>
                                <img src={process.env.REACT_APP_API_BACK_IMG + "/uploads/" + product.owner.picture} alt="dsds" className="w-full h-full object-cover" />
                            </Width>
                            <Width width={"81%"}>
                                <Flex flex="between" align="start">
                                    <Width width={"75%"}>
                                        {product.ispack  ?
                                         <div className="flex justify-between">
                                         <p onClick={e => navigate('/packdetailpage/' + product._id)} className="text-title-teims capitalize  cursor-pointer w-[98%]">{product.owner.businessName}</p>

                                     </div>
                                        :
                                        
                                        <div className="flex justify-between">
                                        <p onClick={e => navigate('/productdetailpage/' + product._id)} className="text-title-teims capitalize  cursor-pointer w-[98%]">{product.owner.businessName}</p>

                                    </div>}
                                        
                                       
                                        <div className="flex justify-between ">
                                            <p className="capitalize cursor-pointer text-title-fab" onClick={e => navigate('/presphoto/' + product.owner._id)}> {product.title}</p>
                                        </div>
                                    </Width>
                                    <Width width={"25%"} className="flex justify-end">
                                        <div className="">
                                            {!isLoading && data == true &&(
                                                <img src={"../images/fav-empty.svg"}  alt="" width={"20px"} onClick={e =>deletes(product._id) } style={{margin:"0.25rem 0 0.25rem auto"}}/>

                                            )}

                                            {!isLoading && data == false &&(
                                                <img src={"../images/fav.svg"}  alt="" width={"20px"} onClick={e => add(product._id)} style={{margin:"0.25rem 0 0.25rem auto"}}/>

                                            )}  
                                            <p className="text-title-fab">{product.categorie?.title}</p>
                                        </div>
                                    </Width>
                                </Flex>
                            </Width>
                        </Flex>
                    </div>
                </div>
            }
        </div>
    )
}